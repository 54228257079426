import React, { useState } from "react";
import { IMAGES } from "../../../assets/images/images";
import loginStyles from "../../../Components/Login/Login.module.css";
import styles from "./OTPVerification.module.css";

const OTPVerification = ({ onSubmit }) => {
  const [otp, setOtp] = useState(new Array(6).fill("")); // For a 6-digit OTP

  // Handle OTP input change
  const handleChange = (element, index) => {
    const value = element.value;
    // Only allow numbers
    if (isNaN(value) && value !== "") return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move focus to the next input if a value is entered
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
    if (!value && element.previousSibling) {
      element.previousSibling.focus();
    }
  };

  // Submit OTP
  const handleSubmit = (e) => {
    e.preventDefault();
    const enteredOtp = otp.join(""); // Join OTP array into a string
    onSubmit(enteredOtp); // Pass the OTP to parent component
  };

  return (
    <div className={`${loginStyles.loginPageWrapper}`}>
      <div className={loginStyles.leftSide}>
        <img src={IMAGES.safeLogo} alt="logo" />
        <p className={loginStyles.presentedByText}>By Sanico USA</p>
      </div>

      <div className={`${loginStyles.rightSide} ${styles.rightSide}`}>
        <h1 className={`${loginStyles.titleHeading} ${styles.titleHeading}`}>
          OTP Verification
        </h1>
        <div className={styles.otpContainer}>
          <label className={styles.descripton}>
            A One-Time Password (OTP) has been sent to your phone.
          </label>
          <form onSubmit={handleSubmit}>
            <div className={styles.otpInputs}>
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className={styles.otpField}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            <button type="submit" className={`${styles.submitBtn}`}>
              Submit OTP
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OTPVerification;
