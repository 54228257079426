import { Card } from "react-bootstrap";
const userCardForm = ({ name, value, label, disabled }) => {
  return (
    <div className="d-flex align-items-center justify-content-start mb-2">
      <label style={{ width: "25%" }}>{label}</label>
      <input
        type="text"
        className="form-control"
        name={name}
        value={value}
        readOnly
        disabled="disabled"
      />
    </div>
  );
};

export default userCardForm;
