export const ROOT_ROUTE = "/dashboard";
export const USERS_ROUTE = "/users";
export const LOGIN_ROUTE = "/login";
export const SITES_ROUTE = "/sites";
export const EMPLOYEE_ROUTE = "/employee";
export const JOB_ORIENTATION = "/job-orientation";
// export const COMPANIES_ROUTE = "/companies";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const CONTACT_US_ROUTE = "/contact-us";
export const PAGE_NOT_FOUND_ROUTE = "/page-not-found";
export const VIDEO_FORM_ROUTE =
  "/video-form/companies/:companyId/sites/:siteId";
export const ADMIN_VIDEO_FORM_ROUTE = "/admin-video-form";
export const OTP_VERIFICATION_ROUTE = "/otp-verification";

export const DASHBOARD_CHART_ROWS = 7;
