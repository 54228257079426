import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import InputFields from "../../../Common/InputField/InputFields";
import classes from "./AddNewSiteModal.module.css";

const AddNewSiteModal = (props) => {
  const {
    setIsShowPopup,
    handleClose,
    editUser,
    allSites,
    setEditUser,
    allCompanies,
    setAddUser,
    newUser,
    handleSubmit,
    errorMsg,
    firstNameError,
    lastNameError,
    phoneError,
    setPhoneError,
    setLastNameError,
    setFirstNameError,
    setEmailError,
    emailError,
    setErrorMsg,
    users,
    isEdit,
    selectedCompanyId,
    setSelectedCompanyId,
  } = props;
  const [passwordStrength, setPasswordStrength] = useState("");
  const [dropDownDisabled, setDropDownDisabled] = useState(false);

  useLayoutEffect(() => {
    if (Object.keys(editUser).length > 0) {
      setAddUser(editUser);
    }
  }, [editUser]);

  useEffect(() => {
    if (isEdit) {
      setSelectedCompanyId(editUser.companyId);
    } else {
      setSelectedCompanyId("");
    }
  }, [isEdit]);

  const getSitesFromCompanies = () => {
    return (
      allCompanies &&
      (
        allCompanies.find((comp) => comp.companyId === selectedCompanyId)
          ?.sites || []
      ).map((s) => (
        <option
          value={s.id}
          selected={!isEdit ? newUser.id === s.id : editUser.id === s.id}
          key={s.id}
        >
          {s.siteName}
        </option>
      ))
    );
  };

  const isDisabled = () => {
    if (
      firstNameError !== "" ||
      lastNameError !== "" ||
      phoneError !== "" ||
      emailError !== "" ||
      errorMsg !== ""
    ) {
      return true;
    }
    return false;
  };
  const isEmailValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const handleChange = (e) => {
    const { name, value } = e.target;
    const isPhoneValid = /^\d+$/;
    const isNameValid = /^[A-Za-z]+$/;
    // let sanitizedValue = "";
    if (value.length === 0) {
      setAddUser({ ...newUser, [name]: "" });
    }
    if (name === "first_name") {
      if (isNameValid.test(value)) {
        setAddUser({ ...newUser, [name]: value });
        setFirstNameError("");
        if (value.length > 50) {
          setFirstNameError(
            "First name must be less than or equal to 50 characters"
          );
        }
      } else {
        setFirstNameError("Only letters accepted");
      }
    } else if (name === "last_name") {
      if (isNameValid.test(value)) {
        setAddUser({ ...newUser, [name]: value });
        setLastNameError("");
        if (value.length > 50) {
          setLastNameError(
            "Last name must be less than or equal to 50 characters"
          );
        }
      } else {
        setLastNameError("Only letters accepted");
      }
    } else if (name === "phone") {
      let sanitizedValue = value.replace(/[-e]/gi, "").replace(/[^0-9]/g, "");
      setAddUser({ ...newUser, [name]: sanitizedValue });
      if (value.length > 15) {
        setPhoneError("Mobile number cannot be greater than 15 digits!");
      } else if (value.length > 0 && value.length < 7) {
        setPhoneError("Mobile number cannot be less than 7 digits!");
      } else {
        setPhoneError("");
      }
      if (isPhoneValid.test(sanitizedValue) && sanitizedValue.length >= 15) {
        setAddUser({ ...newUser, [name]: sanitizedValue });
        // setPhoneError("");
      }
    } else if (name === "email") {
      if (!isEmailValid.test(value)) {
        setEmailError("Invalid email address!");
      } else {
        setEmailError("");
        const emailExist = users.some((user) => user.email === value);
        if (emailExist) {
          setEmailError("Email already exists!");
        }
      }
      setAddUser({
        ...newUser,
        [name]: value.replace(/\s/g, ""),
      });
    } else {
      setAddUser({ ...newUser, [name]: value, emailError: "" });
    }

    if (name === "confirmPassword") {
      if (value === newUser.password) {
        setErrorMsg("");
      } else if (
        value.length > newUser.password.length ||
        value.length !== newUser.password.length
      ) {
        setErrorMsg("Password and Confirm Password must match.");
      }
    } else if (name === "password") {
      if (value === newUser.confirmPassword) {
        setErrorMsg("");
      } else {
        setErrorMsg("Password and Confirm Password must match.");
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      // Prevent cursor from moving forward when space key is pressed
      e.preventDefault();
    }
  };
  const handleChangeSite = (e) => {
    if (isEdit) {
      setEditUser({ ...newUser, siteId: e.target.value });
    } else {
      setAddUser({ ...newUser, siteId: e.target.value });
    }
    setDropDownDisabled(true);
  };
  const handleChangeCompanies = (e) => {
    setAddUser({ ...newUser, companyId: e.target.value });
    setSelectedCompanyId(e.target.value);
    setDropDownDisabled(false);
  };

  const hasUpperCase = /[A-Z]/.test(newUser.password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newUser.password);

  const checkPasswordStrength = (password) => {
    if (password === 0) {
      return "";
    } else if (password >= 6 && hasUpperCase && hasSpecialChar) {
      return "Strong";
    } else if (password >= 5) {
      return "Normal";
    } else {
      return "Weak";
    }
  };

  useEffect(() => {
    if (!isEdit && newUser.password === "") {
      setAddUser({ ...newUser, ["password"]: "" });
      setPasswordStrength("");
    }
    if (!isEdit && newUser.password.length > 0) {
      const strength = checkPasswordStrength(newUser.password.length);
      setPasswordStrength(strength);
    }
  }, [newUser.password]);
  const firstPasswordStrength = () => {
    const passwordLength = newUser.password.length;
    if (passwordLength === 0) {
      return classes.passwordGrayDots;
    } else if (passwordLength > 0 && passwordLength < 2) {
      return classes.passwordOrangeDots;
    } else {
      return classes.passwordGreenDots;
    }
  };

  const secondPasswordStrength = () => {
    const passwordLength = newUser.password.length;
    if (passwordLength >= 0 && passwordLength < 3) {
      return classes.passwordGrayDots;
    } else if (passwordLength >= 3 && passwordLength < 5) {
      return classes.passwordOrangeDots;
    } else {
      return classes.passwordGreenDots;
    }
  };

  const thirdPasswordStrength = () => {
    const passwordLength = newUser.password.length;
    if (passwordLength >= 0 && passwordLength <= 5) {
      return classes.passwordGrayDots;
    } else if (passwordLength > 5 && hasUpperCase && hasSpecialChar) {
      return classes.passwordGreenDots;
    } else if (passwordLength > 5 && hasUpperCase) {
      return classes.passwordOrangeDots;
    } else {
      return classes.passwordOrangeDots;
    }
  };

  // Check if all input fields are valid
  const isFormValid = () => {
    return (
      newUser.first_name !== "" &&
      newUser.last_name !== "" &&
      newUser.phone !== "" &&
      newUser.email !== "" &&
      newUser.siteId !== "" &&
      newUser.companyId !== "" &&
      newUser.password !== "" &&
      newUser.confirmPassword !== "" &&
      newUser.password === newUser.confirmPassword
    );
  };

  const isEditFormValid = () => {
    return (
      newUser.first_name !== "" &&
      newUser.last_name !== "" &&
      newUser.phone !== "" &&
      newUser.email !== "" &&
      newUser.companyId !== "" &&
      newUser.siteId !== ""
    );
  };

  return (
    <div>
      <Modal
        show={setIsShowPopup}
        onHide={handleClose}
        size="xl"
        className={`${classes.addNewModalMain} user-modal-edit`}
      >
        <Modal.Header className="modal-user" closeButton />
        <Modal.Body className={`${classes.addNewBody}`}>
          <div className={`${classes.addNewUserWrapper}`}>
            <Form onSubmit={handleSubmit}>
              <div className={`${classes.addNewUserForm}`}>
                <h4 className={`${classes.mainHeading} text-center mb-5`}>
                  {!isEdit ? "Add New" : "Update"} Site Admin
                </h4>
                <div className={`${classes.addUserform} form`}>
                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <InputFields
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="First Name"
                        placeholder="First Name"
                        name="first_name"
                        value={newUser.first_name}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span className="text-danger">{firstNameError}</span>
                    </div>
                    <div className="col-lg-4">
                      <InputFields
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Last Name"
                        placeholder="Last Name"
                        name="last_name"
                        value={newUser.last_name}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span className="text-danger">{lastNameError}</span>
                    </div>
                    <div className="col-lg-4">
                      <InputFields
                        type="tel"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Mobile No."
                        placeholder="1 (555) 555-5555"
                        name="phone"
                        limit="15"
                        value={newUser.phone}
                        handleChange={(e) => handleChange(e)}
                      />
                      <span className="text-danger">{phoneError}</span>
                    </div>
                  </div>
                  <div className="row row-2 mb-3">
                    <div className="col-lg-4">
                      <InputFields
                        type="email"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Email"
                        placeholder="example@gmail.com "
                        name="email"
                        disabled={isEdit}
                        readonly={isEdit ? "readonly" : ""}
                        value={newUser.email}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span className="text-danger">{emailError}</span>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className={`${classes.formGroupWrapper}`}>
                        <label
                          className={`${classes.selectOptionLabel} form-label`}
                        >
                          Companies
                        </label>
                        <div className={`${classes.customSelectWrapper}`}>
                          <select
                            className={`${classes.customSelect} form-control`}
                            value={newUser.companyId}
                            onChange={handleChangeCompanies}
                            // disabled={Object.keys(editUser).length > 0}
                          >
                            {(!isEdit
                              ? newUser.companyId === ""
                              : editUser.companyId === "" ||
                                newUser.companyId === undefined) && (
                              <option disabled={dropDownDisabled} value="">
                                Select
                              </option>
                            )}
                            {allCompanies &&
                              allCompanies.map((s) => (
                                <option value={s.companyId} key={s.id}>
                                  {s.companyname}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className={`${classes.formGroupWrapper}`}>
                        <label
                          className={`${classes.selectOptionLabel} form-label`}
                        >
                          Sites
                        </label>
                        <div className={`${classes.customSelectWrapper}`}>
                          <select
                            disabled={!newUser.companyId}
                            className={`${classes.customSelect} form-control`}
                            value={!isEdit ? newUser.siteId : editUser.siteId}
                            onChange={handleChangeSite}
                            // disabled={Object.keys(editUser).length > 0}
                          >
                            {(!isEdit ? !newUser.siteId : !editUser.siteId) ? (
                              <option disabled={dropDownDisabled}>
                                Select
                              </option>
                            ) : null}
                            {getSitesFromCompanies()}
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  {!isEdit && (
                    <div className="row row-3">
                      <div className="col-lg-6">
                        <div
                          className={`${classes.passwordFiled} position-relative`}
                        >
                          <InputFields
                            type="password"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label="Password"
                            id="passwordInput"
                            placeholder=""
                            name="password"
                            cssClass="pe-4"
                            value={newUser.password}
                            handleChange={(e) => handleChange(e)}
                            handleKeyDown={handleKeyDown}
                          />

                          <div
                            className="d-flex align-items-start pt-2 justify-content-between font-xs"
                            style={{ columnGap: "5px" }}
                          >
                            <span>
                              Please choose a stronger password. Try a mix of
                              letters, <br /> numbers and symbols.
                            </span>
                            <div
                              className={`${classes.strongPasswordfield} d-flex align-items-center justify-content-end pt-1`}
                            >
                              <span className={firstPasswordStrength()} />
                              <span className={secondPasswordStrength()} />
                              <span className={thirdPasswordStrength()} />
                              <span className={`${classes.strongText} ms-2`}>
                                {passwordStrength}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          className={`${classes.passwordFiled} position-relative`}
                        >
                          <InputFields
                            type="password"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label="Confirm Password"
                            placeholder=""
                            name="confirmPassword"
                            cssClass="pe-4"
                            value={newUser.confirmPassword}
                            handleChange={(e) => handleChange(e)}
                            handleKeyDown={handleKeyDown}
                          />
                        </div>
                        <span className="text-danger">{errorMsg}</span>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-12 pt-5 mb-1">
                    <Button
                      variant="primary"
                      className={`${classes.signUpBtn}`}
                      // type="submit"
                      // onClick={handleSubmit}
                      onClick={handleSubmit}
                      disabled={
                        (isEdit ? !isEditFormValid() : !isFormValid()) ||
                        isDisabled()
                      }
                    >
                      {!isEdit ? "Add Admin" : "Update"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNewSiteModal;
