import axios from "axios";
import React, { useEffect, useState } from "react";
import { GET_All_VIDEO_EMPLOYEES } from "../../services/URL";
import style from "./AdminPage.module.css";
import EmployeeDataTable from "./EmployeeTable/EmployeeDataTable"; // Import the new data table component

const AdminPage = () => {
  const [employees, setEmployees] = useState([]);
  // const { companyId, siteId } = useParams();
  const companyId = localStorage.getItem("companyId");
  const siteId = localStorage.getItem("siteId");

  const videoLinkURL = `https://admin.secure-scan.live/video-form/companies/${companyId}/sites/${siteId}`;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem("type") || "";
    if (userRole === "admin") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.post(GET_All_VIDEO_EMPLOYEES, {
          companyId,
          siteId,
        });
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  // Convert seconds to MM:SS format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Format date to a readable format
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(videoLinkURL);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <div className={style.userDashboardWrapper}>
      <div className={`${style.userDashboard} w-100`}>
        <div className={`${style.leftSide} `}>
          <div className="text-center mb-3">
            <h1>Employee Video Watch Status</h1>
          </div>
          <EmployeeDataTable
            employees={employees}
            formatTime={formatTime}
            formatDate={formatDate}
            handleCopyLink={handleCopyLink}
            copied={copied}
            videoLinkURL={videoLinkURL}
            companyId={companyId}
            siteId={siteId}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
