import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ButtonUser from "../../Components/Common/Button/ButtonUser";
import InputFields from "../../Components/Common/InputField/InputFields";
import Loader from "../../Components/Common/Loader/Loader";
import SearchBar from "../../Components/Common/Searchbar/SearchBar";
import AddNewSiteModal from "../../Components/Common/SitePage/AddNewSiteModal/AddNewSiteModal";
import SiteDataTable from "../../Components/SiteTable/SiteDataTable";
import classes from "../../Components/UserPage/Index.module.css";
import { IMAGES } from "../../assets/images/images";
import {
  ACTIVE_INACTIVE,
  DELETE_USER,
  GET_COMPANIES,
  GET_SITES,
  LOGOUT,
  RESET_CREDENTIALS,
  SIGNUP,
  UPDATE_PROFILE,
} from "../../services/URL";
import { api } from "../../services/auth.serice";
import { AiOutlineUserAdd } from "react-icons/ai";
var FormData = require("form-data");

const Sites = () => {
  const navigate = useNavigate();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isAddNewModal, setIsaddNewModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState(false);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deletedUser, setDeletedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [resetUser, setResetUser] = useState(null);
  const [resetShowModal, setResetShowModal] = useState(false);
  const [allSites, setSites] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [addUser, setAddUser] = useState({
    id: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    siteId: "",
    password: "",
    type: "site admin",
    isApprove: true,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const [resetErrorMail, setResetErrorMail] = useState("");
  const [resetErrorPass, setResetErrorPass] = useState("");
  const [resetErrorCPass, setResetErrorCPass] = useState("");

  const [resetCredentials, setResetCredentials] = useState({
    email: "",
    password: "",
    confirmPass: "",
  });
  const handleClick = () => {
    setIsShowPopup(true);
    setIsEdit(false);
    setAddUser({
      id: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      companyId: "",
      siteId: "",
      password: "",
      type: "site admin",
      isApprove: true,
    });
  };
  const handleClose = () => {
    setIsShowPopup(false);
    setAddUser({
      id: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      companyId: "",
      siteId: "",
      password: "",
      type: "site admin",
      isApprove: true,
    });
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setErrorMsg("");
    setEditUser({});
    // setIsaddNewModal(false);
  };
  useLayoutEffect(() => {
    if (searchText.length > 0) {
      handleSearch().then();
    } else {
      getAllSitesAdmin();
    }
  }, [searchText]);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const res = await api.searchAdmins({ name: searchText });
      if (res.status === 200) {
        setUsers([...(res.data || [])]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("handleSearch", error);
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    getAllSitesAdmin().then();
    // getSites().then();
    getAllCompanies();
  }, []);
  const getAllSitesAdmin = async () => {
    setIsLoading(true);
    const res = await api.getAllSiteAdmins();
    if (res.status === 200) {
      setUsers(res.data);
      setIsLoading(false);
      localStorage.setItem("users", JSON.stringify(res.data));
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sort) {
      const sortArr = users?.sort((a, b) => {
        const { first_name: fa } = a;
        const { first_name: fb } = b;
        if (fa?.toLowerCase() < fb?.toLowerCase()) {
          return -1;
        }
        if (fa?.toLowerCase() > fb?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([...sortArr]);
    }
  }, [sort]);

  useEffect(() => {
    if (Object.keys(editUser).length > 0) {
      setEditUser(editUser);
    }
  }, [editUser]);

  const handleApprove = (userId, val) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setIsLoading(true);
    axios
      .post(ACTIVE_INACTIVE, { id: userId, value: !val }, { headers })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // getAllCompanies();
          if (searchText !== "") {
            handleSearch();
          } else {
            getAllSitesAdmin();
          }
        } else {
          Swal.fire(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error");
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      if (!deletedUser) return;
      const response = await axios.delete(DELETE_USER, {
        headers,
        data: { id: deletedUser },
      });
      if (response.data.status === 200) {
        Swal.fire("User has been deleted successfully");
        if (searchText !== "") {
          handleSearch();
        } else {
          getAllSitesAdmin();
        }
        setShowDeleteModal(false);
      } else {
        Swal.fire({
          icon: "error",
          title: response.data.error[0].message,
          text: response.data.error[0].message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setIsLoading(false);
    } catch (error) {
      // Handle error if deletion fails
      Swal.fire("Failed to delete item");
      setIsLoading(false);
    }
  };

  const handleShowDeleteModal = (id) => {
    setDeletedUser(id);
    setShowDeleteModal(true);
  };
  const handleResetModal = (user) => {
    setResetUser(user._id);
    // setResetCredentials({ ...resetCredentials, email: user.email });
    setResetShowModal(true);
  };
  const handleNameSort = (column, sortDirection) => {
    const sortedUsers = [...users]; // Make a copy of the original 'users' array

    sortedUsers.sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toUpperCase();
      const nameB = `${b.first_name} ${b.last_name}`.toUpperCase();

      if (nameA < nameB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      // If the names are equal, sort by email
      const emailA = a.email.toUpperCase();
      const emailB = b.email.toUpperCase();

      if (emailA < emailB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (emailA > emailB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      return 0;
    });

    // Update the state with the sorted array
    setUsers(sortedUsers);
  };

  // const getSites = async (companyId) => {
  //   axios
  //     .post(GET_SITES, { companyId })
  //     .then((response) => {
  //       setSites(response.data.data);
  //       console.log("responseSite", response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getAllCompanies = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(GET_COMPANIES, { headers });
      setAllCompanies(response.data.data);
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };

  // useEffect(() => {
  //   localStorage.setItem("companyId", selectedCompanyId);
  //   if (selectedCompanyId) getSites(selectedCompanyId).then();
  //   console.log("useEffectCompanyId", selectedCompanyId);
  // }, [selectedCompanyId]);

  const search = useCallback((e) => {
    setSearchText(e);
  }, []);
  const handleShowEditModal = (e) => {
    setIsShowPopup(true);
    // getAllCompanies();
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    setIsDisabled(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      let response;

      if (!isEdit) {
        response = await axios.post(SIGNUP, addUser, { headers });
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Added Successfully",
            text: "Admin Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setAddUser({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            siteId: "",
            password: "",
            isApproved: false,
          });
          setIsShowPopup(false);
          setIsDisabled(false);
          setIsLoading(false);
          getAllSitesAdmin();
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.error[0],
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        }
      } else {
        if (addUser.siteId === null) addUser.siteId = editUser.siteId;
        delete addUser.email;
        var data = new FormData();
        data.append("id", editUser._id);
        data.append("first_name", addUser.first_name);
        data.append("last_name", addUser.last_name);
        data.append("phone", addUser.phone);
        data.append("companyId", addUser.companyId);
        data.append("siteId", addUser.siteId);
        data.append("type", "site admin");
        var config = {
          method: "post",
          url: UPDATE_PROFILE,
          headers: headers,
          data: data,
        };
        axios(config)
          .then(function (response) {
            if (response.data.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Updated Successfully",
                text: `Admin Updated Successfully`,
                showConfirmButton: false,
                timer: 1500,
              });
              setIsShowPopup(false);
              setAddUser({
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                companyId: "",
                siteId: "",
                password: "",
                isApproved: false,
              });
              if (searchText !== "") {
                handleSearch();
              } else {
                getAllSitesAdmin();
              }
              setEditUser({});
              setIsLoading(false);
            } else {
              Swal.fire({
                icon: "error",
                text: response.data.error[0],
                showConfirmButton: false,
                timer: 1500,
              });
              setIsLoading(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setIsLoading(false);
          });
      }
    } catch (error) {
      try {
        const payload = {
          id: localStorage.getItem("user"),
        };
        const response = await axios.post(LOGOUT, payload, {
          headers,
        });
        localStorage.removeItem("users");
        localStorage.removeItem("user");
        localStorage.removeItem("type");
        // setIsLoading(false);
        navigate("/login");
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };
  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setResetCredentials({ ...resetCredentials, [name]: value });

    if (name === "confirmPass") {
      if (value === resetCredentials.password) {
        setResetErrorCPass("");
      } else if (
        value.length > resetCredentials.password.length ||
        value.length !== resetCredentials.password.length
      ) {
        setResetErrorCPass("Password and Confirm Password must match.");
      }
    } else if (name === "password") {
      if (value === resetCredentials.confirmPass) {
        setResetErrorCPass("");
      } else {
        setResetErrorCPass("Password and Confirm Password must match.");
      }
    }
  };
  const handleReset = async (id, password) => {
    setIsLoading(true);
    setIsDisabled(true);
    let response;
    try {
      response = await axios.post(RESET_CREDENTIALS, {
        id,
        password,
      });
      if (response.data.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Reset Successfully",
          text: "Admin Reset Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setResetCredentials({
          password: "",
        });
        // setIsDisabled(false);
        setIsLoading(false);
        setResetShowModal(false);
        getAllSitesAdmin();
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.error[0],
          showConfirmButton: false,
          timer: 1500,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      // Prevent cursor from moving forward when space key is pressed
      e.preventDefault();
    }
  };
  const resetButtonDisbaled =
    resetCredentials.password === "" ||
    resetCredentials.confirmPass === "" ||
    resetCredentials.password !== resetCredentials.confirmPass;
  return (
    <d className={classes.userDashboardWrapper}>
      <div className={`${classes.userDashboard} w-100`}>
        <div className={`${classes.leftSide} `}>
          <div>
            <h1>Secure Scan Admin</h1>
          </div>
          <div className="row py-4">
            <div className="col-sm-5 position-relative">
              <SearchBar
                placeholder="Search Admin"
                search={(e) => search(e)}
                handleSort={() => setSort(true)}
              />
            </div>
            <div className="col-lg-3 col-sm-5 col-md-4">
              <ButtonUser handleClick={handleClick} label="Add New Admin" icon={<AiOutlineUserAdd />} />
            </div>
          </div>
          <SiteDataTable
            users={users}
            handleEdit={(e) => setEditUser(e)}
            editUser={editUser}
            handleApprove={(id, val) => handleApprove(id, val)}
            handleClose={handleClose}
            handleShowDeleteModal={(e) => handleShowDeleteModal(e)}
            handleShowEditModal={(e) => handleShowEditModal(e)}
            handleResetModal={(e) => handleResetModal(e)}
            isShowPopup={isShowPopup}
            setIsShowPopup={setIsShowPopup}
            handleNameSort={handleNameSort}
            sites={allSites}
            isEdit={setIsEdit}
            allCompanies={allCompanies}
          />
        </div>
      </div>
      {isShowPopup && (
        <AddNewSiteModal
          handleClose={handleClose}
          setIsaddNewModal={setIsaddNewModal}
          allSites={allSites}
          allCompanies={allCompanies}
          users={users}
          setAddUser={setAddUser}
          newUser={addUser}
          handleSubmit={handleSubmit}
          firstNameError={firstNameError}
          lastNameError={lastNameError}
          setLastNameError={setLastNameError}
          setFirstNameError={setFirstNameError}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
          emailError={emailError}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          setIsShowPopup={setIsShowPopup}
          setEmailError={setEmailError}
          editUser={editUser}
          setEditUser={setEditUser}
          isEdit={isEdit}
          setSelectedCompanyId={setSelectedCompanyId}
          selectedCompanyId={selectedCompanyId}
        />
      )}
      {resetShowModal && (
        <>
          <Modal
            show={setResetShowModal}
            onHide={() => setResetShowModal(false)}
            size="md"
          >
            <Modal.Header className="modal-user" closeButton />
            <Modal.Body className="p-5">
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <InputFields
                    type="password"
                    inputStyle="modalInput"
                    labelStyle="text-capitalize"
                    label="Password"
                    placeholder="Password"
                    name="password"
                    cssClass="pe-4"
                    value={resetCredentials.password}
                    handleChange={handleResetChange}
                    handleKeyDown={handleKeyDown}
                  />
                </div>
                <div className="col-sm-12 mb-2">
                  <InputFields
                    type="password"
                    inputStyle="modalInput"
                    labelStyle="text-capitalize"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    name="confirmPass"
                    cssClass="pe-4"
                    value={resetCredentials.confirmPass}
                    handleChange={handleResetChange}
                    handleKeyDown={handleKeyDown}
                  />
                  <span className="text-danger text-xs">{resetErrorCPass}</span>
                </div>
              </div>
              <div className="col-sm-12 text-end mt-3 w-100">
                <Button
                  className="btn-success"
                  disabled={resetButtonDisbaled}
                  onClick={() =>
                    handleReset(resetUser, resetCredentials.password)
                  }
                >
                  Reset
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
      {showDeleteModal && (
        <div className={classes.deletePopupWrapper}>
          <div className={classes.deletePopupInner}>
            <img src={IMAGES.exclamationIcon} alt="exclamaiton icon" />
            <p>Are you sure you want to delete this user?</p>
            <p>
              This action cannot be undone and you will not be able to recover
              this data.
            </p>
            <div className={classes.btnWrapper}>
              <button
                className={classes.deleteConfirmButton}
                onClick={() => handleDelete()}
              >
                Yes, delete
              </button>
              <button
                className={classes.deleteCancelButton}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </d>
  );
};

export default Sites;
