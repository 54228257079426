import React, { useEffect, useState, useRef } from "react";
import classes from "./Index.module.css";
import DataTable from "react-data-table-component";
import avatar from "../../assets/images/avatar.jpg";
import { IMAGES } from "../../assets/images/images";
import Loader from "../Common/Loader/Loader";
const SiteDataTable = (props) => {
  const dropdownRef = useRef(null);
  // Effect to handle outside click to close the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdownItemId(null);
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [activeDropdownItemId, setActiveDropdownItemId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    users,
    handleEdit,
    handleShowEditModal,
    handleApprove,
    handleShowDeleteModal,
    handleResetModal,
    handleNameSort,
    sites,
    allCompanies,
    isEdit,
  } = props;
  const [selectedItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(10);

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return {
          color: "rgba(255, 197, 7, 1)",
          background: "#FBF9DE",
        };
      case "Deactivated":
        return {
          color: "#FF3D00",
          background: "#f0808021",
        };

      default:
        return {
          color: "#64B431",
          background: "#90ee9024",
        };
    }
  };
  const getStatus = (item) => {
    if (item.status === "Activated") {
      return "Activated";
    } else if (item.status === "Deactivated") {
      return "Deactivated";
    }
    return "Pending";
  };

  // const getSite = (item) => {
  //   if (item.companyId !== undefined && item.companyId !== "") {
  //     const companyIdString = item.companyId.toString();
  //     const site = sites.find(
  //       (s) => s.companyId.tostring() === companyIdString
  //     );
  //     return site ? site.siteName : "N/A";
  //   } else {
  //     return "N/A";
  //   }
  // };

  const getSiteID = (item) => {
    // if (item.siteId !== "" && item.siteId !== undefined) {
    // const site = users.find(
    //   (s) => (s.siteId || "").toString() === item.siteId
    // );
    return item ? item.siteId : "N/A";
    // } else {
    //   return "N/A";
    // }
  };

  //   const getActivity = (item) => {
  //     const currentDatetime = new Date();
  //     const givenDatetime = new Date(item.updatedAt);
  //     const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  //     const timeDifference = currentDatetime - givenDatetime;
  //     const daysDifference = Math.floor(timeDifference / millisecondsPerDay);

  //     return daysDifference === 0 || daysDifference < 0
  //       ? "Today"
  //       : `${daysDifference} day ago`;
  //   };

  const getIndex = (index) => {
    if (currentPage === 1) {
      return index + 1;
    } else {
      return index + 1 + (currentPage - 1) * skip;
    }
  };

  const columns = [
    {
      name: <b>Id</b>,
      selector: (_, index) => getIndex(index),
      sortable: false,
      width: "50px",
    },
    {
      name: <b>Site ID</b>,
      selector: (item) => item.siteId,
      sortable: false,
    },
    {
      name: <b>Site</b>,
      selector: (item) => item.siteName,
      sortable: false,
    },
    {
      name: <b>Users</b>,
      selector: (item) => (
        <div className="d-flex align-items-center">
          <div>
            <div
              className={`${classes.itemProfile} rounded-circle overflow-hidden`}
            >
              <img
                src={item.profileImage || avatar}
                alt=""
                className={`${classes.itemImage}`}
              />
            </div>
          </div>
          <span
            className={`${classes.itemName}`}
            title={`${item.first_name} ${item.last_name}`}
          >
            {item.first_name} {item.last_name}
          </span>
        </div>
      ),
      minWidth: "200px",
      sortable: true,
    },

    {
      name: <b>Status</b>,
      selector: (item) => (
        <span
          style={getStatusColor(item.status)}
          className={`${
            item.isApproved ? "deactivatedIconWrapper" : "activatedIconWrapper"
          }`}
        >
          {getStatus(item)}
        </span>
      ),
      sortable: false,
    },

    // {
    //   name: <b>Phone No.</b>,
    //   selector: (item) => <span title={item.phone}>{item.phone}</span>,
    //   sortable: false,
    // },
    {
      name: <b>Email</b>,
      selector: (item) => <span title={item.email}>{item.email}</span>,
      sortable: true,
      minWidth: "250px",
    },

    // {
    //   name: <b>Activity</b>,
    //   selector: (item) => getActivity(item),
    //   width: "130px",
    // },

    {
      name: <b></b>,
      selector: (item) => {
        const isDropdownActive = activeDropdownItemId === item._id;
        return (
          <>
            <div>
              {isDropdownActive && (
                <div
                  className={`${classes.dropdown} d-flex flex-column`}
                  ref={dropdownRef}
                >
                  <div
                    className={`cursor-pointer ${classes.dropDownItem} `}
                    onClick={() => {
                      handleEdit(item);
                      handleShowEditModal(item._id);
                      isEdit(true);
                    }}
                  >
                    Edit
                  </div>
                  <div>
                    <div className={`cursor-pointer ${classes.dropDownItem} `}>
                      <span
                        className={`cursor-pointer ${
                          item.isApproved
                            ? "deactivatedIconWrappr"
                            : "activatedIconWraper"
                        }`}
                        onClick={() => handleApprove(item._id, item.isApproved)}
                      >
                        {item.isApproved ? "Deactivate" : "Activate"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`cursor-pointer ${classes.dropDownItem}`}
                    onClick={() => handleShowDeleteModal(item._id)}
                  >
                    Delete
                  </div>
                  <div
                    className={`cursor-pointer ${classes.dropDownItem} ${classes.dropDownItemDelete}  `}
                    onClick={() => handleResetModal(item)}
                  >
                    Reset Password
                  </div>
                </div>
              )}
            </div>
            <div
              className={`d-flex ${classes.dropdownToggle}`}
              onClick={() => {
                if (isDropdownActive) {
                  setActiveDropdownItemId(null);
                  setIsDropdownOpen(false);
                } else {
                  setActiveDropdownItemId(item._id);
                  setIsDropdownOpen(true);
                }
              }}
            >
              <div
                className={`${
                  isDropdownActive ? classes.kababMenuActive : classes.kababMenu
                }`}
              >
                <img src={IMAGES.kababMenu} alt="" />
              </div>
            </div>
          </>
        );
      },
      width: "50px",
    },
  ];

  const onPageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePerRowsChange = (s) => {
    setSkip(s);
  };

  return (
    <div className="data-table-wrapper">
      <div id="userDataTable" className={`${classes.userDataTable}`}>
        <DataTable
          // title="User Data"
          columns={columns}
          data={users}
          pagination
          highlightOnHover
          onSort={handleNameSort}
          onChangeRowsPerPage={(s) => handlePerRowsChange(s)}
          onChangePage={(pageNumber) => onPageChange(pageNumber)}
        />
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default SiteDataTable;
