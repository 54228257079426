import React, { useRef, useEffect, useLayoutEffect } from "react";
import { Pie, Chart } from "react-chartjs-2";
import styles from "./Dount.module.css";

const PieChart = (props) => {
  const { users } = props;
  const chartContainer = useRef(null);
  let chartInstance = null;
  const data = {
    labels: [],
    datasets: [
      {
        data: [users.active, users.inactive, users.pending],
        backgroundColor: ["#64B431", "#FE6431", "#FFD54C"],
      },
    ],
  };

  const options = {
    title: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 7,
      },
    },
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(chartContainer.current, {
        type: "pie",
        data: data,
        options: options,
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, []);

  return (
    <div className={`${styles.pieChartCanvas}`}>
      <canvas ref={chartContainer}></canvas>
    </div>
  );
};

export default PieChart;
