import React from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import classes from "./Index.module.css";

const ButtonUser = ({ handleClick, label, icon, className, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={`${classes.UserBtn} btn-lg align-items-center d-flex justify-content-center ${className}`}
      onClick={handleClick}
    >
      {icon && icon}
      <span>{label}</span>
    </button>
  );
};

export default ButtonUser;
