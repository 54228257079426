import React, { useEffect, useRef } from "react";
import { Chart } from "react-chartjs-2";
import styles from "./DoubleLineChart.module.css";
import { getChartMaxRows, getChartStepSize } from "../../utils";

const DoubleLineBarChart = (props) => {
  const { events } = props;
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  // Function to create custom gradient colors
  const getGradientColors = (startColor, endColor) => {
    const ctx = chartRef.current?.getContext("2d");
    if (ctx) {
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, startColor);
      gradient.addColorStop(1, endColor);
      return gradient;
    }
    return startColor; // Fallback to start color if gradient cannot be created
  };

  const maxValid = events ? Math.max(...events.map((e) => e.valid_access)) : 0;
  const maxInvalid = events
    ? Math.max(...events.map((e) => e.invalid_access))
    : 0;
  function isEven(num) {
    return num % 2 === 0;
  }
  const data = {
    labels: events ? events.map((e) => e.day) : [],
    datasets: [
      {
        label: "Valid",
        data: events ? events.map((e) => e.valid_access || 0) : [],
        backgroundColor: getGradientColors(
          "rgba(130, 195, 88, 1)",
          "rgba(235, 247, 226)"
        ),
        borderColor: "transparent",
        borderWidth: 1,
      },
      {
        label: "Invalid",
        data: events ? events.map((e) => e.invalid_access || 0) : [],
        backgroundColor: getGradientColors(
          "rgba(254, 100, 49, 1)",
          "rgba(235, 247, 226)"
        ),
        borderColor: "transparent",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        max: getChartMaxRows(Math.max(maxValid, maxInvalid) || 7),
        ticks: {
          precision: 0,
          stepSize: getChartStepSize(Math.max(maxValid, maxInvalid) || 7),
        },
      },
    },
  };

  useEffect(() => {
    if (chartInstance.current) {
      // Destroy the existing chart instance if it exists
      chartInstance.current.destroy();
    }

    // Create a new chart instance
    chartInstance.current = new Chart(chartRef.current, {
      type: "bar",
      data: data,
      options: options,
    });

    // Cleanup: Destroy the chart instance on component unmount
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, options]);

  return (
    <div>
      <div className="ChartEvents">
        <canvas ref={chartRef} />
      </div>
      <div className={`${styles.DoubleChartLabel} pt-4 mb-3 d-flex`}>
        <div className={`${styles.colXs6} col-xs-6 col-sm-4`}>
          <label className={`${styles.eventnsLabel1} ps-4 position-relative`}>
            Valid Access
          </label>
        </div>
        <div className={`${styles.colXs - 6} col-xs-6 col-sm-4`}>
          <label className={`${styles.eventLabel2} ps-4 position-relative`}>
            Invalid Access
          </label>
        </div>
      </div>
    </div>
  );
};

export default DoubleLineBarChart;
