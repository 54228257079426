import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { IoCheckmarkOutline, IoCopyOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ButtonUser from "../../Components/Common/Button/ButtonUser";
import RadioSlideButton from "../../Components/Common/RadioSlideButton/RadioSlideButton";
import { ADD_VIDEO_DETAILS, GET_VIDEO_DETAILS } from "../../services/URL";
import styles from "./JobOrientationForm.module.css";

const JobOrientationForm = () => {
  const companyId = localStorage.getItem("companyId");
  const siteId = localStorage.getItem("siteId");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  const videoLinkURL = `https://admin.secure-scan.live/video-form/companies/${companyId}/sites/${siteId}`;

  const [formValues, setFormValues] = useState({
    jobTitle: "",
    videoLink: "",
    notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setIsChanged(true);
  };
  useEffect(() => {
    const userRole = localStorage.getItem("type")||"";
    if (userRole === "admin") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.post(GET_VIDEO_DETAILS, {
          companyId,
          siteId,
        });

        if (response.data) {
          const { jobTitle, videoLink, notes, isActive } = (
            response?.data || {}
          ).videoDetails;
          setFormValues({ jobTitle, videoLink, notes });
        }
      } catch (error) {
        console.error("Error fetching video details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoDetails();
  }, [companyId, siteId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(ADD_VIDEO_DETAILS, {
        jobTitle: formValues.jobTitle,
        videoLink: formValues.videoLink,
        notes: formValues.notes,
        companyId,
        siteId,
        jobVisibility:isActive,
      });
      if(response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your data has been submitted successfully.",
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again.",
        });
      }
     
      // setFormValues({
      //   jobTitle: "",
      //   videoLink: "",
      //   notes: "",
      // });
      setIsChanged(false);
    } catch (error) {
      console.error("Error posting data:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again.",
      });
    }
  };
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(videoLinkURL);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };
  return (
    <div className={styles.userDashboardWrapper}>
      <div className={`${styles.userDashboard} w-100`}>
        <div className={`${styles.leftSide} `}>
          <div className="text-center">
            <h1>Job Orientation</h1>
          </div>
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <div className={styles.formContainer}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formJobTitle" className="mb-3">
                      <Form.Label className={styles.label}>
                        Job Title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="jobTitle"
                        value={formValues.jobTitle}
                        onChange={handleInputChange}
                        placeholder="Enter job title"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formVideoLink" className="mb-3">
                      <Form.Label className={styles.label}>
                        Video Link
                      </Form.Label>
                      <Form.Control
                        type="url"
                        name="videoLink"
                        value={formValues.videoLink}
                        onChange={handleInputChange}
                        placeholder="Enter video link "
                      />
                    </Form.Group>
                    <Form.Group controlId="formNotes" className="mb-3">
                      <Form.Label className={styles.label}>Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="notes"
                        value={formValues.notes}
                        onChange={handleInputChange}
                        rows={3}
                        placeholder="Enter any additional notes"
                      />
                    </Form.Group>
                    <div>
                      {/* <label className={styles.label}>Job Visibility</label> */}
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <RadioSlideButton
                          firstLabel="Activated"
                          secondLabel="disabled"
                          isActive={isActive}
                          setIsActive={(value) => {
                            setIsActive(value);
                            setIsChanged(true);
                          }}
                        /> */}
                        <ButtonUser
                          variant="primary"
                          label="Submit"
                          type="submit"
                          disabled={!isChanged}
                          className={styles.submitBtn}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
                <div className=" mt-2 ">
                  Go to:{" "}
                  <Link
                    to={`/video-form/companies/${companyId}/sites/${siteId}`}
                    className="text-primary text-decoration-underline fs-7 me-2"
                  >
                    {videoLinkURL}
                  </Link>
                  {copied ? (
                    <IoCheckmarkOutline
                      title="Copied!"
                      fontSize="18"
                      style={{
                        cursor: "pointer",
                        color: "green",
                      }}
                    />
                  ) : (
                    <IoCopyOutline
                      title="Copy"
                      fontSize="18"
                      style={{ cursor: "pointer" }}
                      onClick={handleCopyLink}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default JobOrientationForm;
